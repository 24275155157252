<template>
    <div>
        <el-dialog title="采集结果" :visible.sync="dialogTableVisible" :close-on-click-modal="false" append-to-body class="dialogClass"  width="1500px" :before-close="handleClose">
            <el-table
                    ref="multipleTable"
                    :data="tableData"
                    tooltip-effect="dark"
                    :row-class-name="tableRowClassName"
                    @selection-change="handleSelectionChange">
                <el-table-column
                        label="标题"
                        align="center"
                        prop="title"
                        show-overflow-tooltip
                        width="350">
                </el-table-column>
                <el-table-column
                        label="网址"
                        align="center"
                        prop="sourceUrl"
                        width="350">
                </el-table-column>
                <el-table-column
                        prop="isAcquisitionSucceeded"
                        align="center"
                        label="采集结果"
                        width="120">
                    <template slot-scope="scope">
                        <el-tag size="small" v-if="scope.row.isAcquisitionSucceeded === 1" type="success">成功</el-tag>
                        <el-tag size="small" v-else-if="scope.row.isAcquisitionSucceeded ===2"  type="info">失败</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        label="发布时间"
                        align="center"
                        prop="releaseTime"
                        width="200"
                ></el-table-column>
                <el-table-column
                        label="作者"
                        align="center"
                        prop="author"
                        width="120"
                ></el-table-column>
                <el-table-column
                        label="发布者"
                        align="center"
                        prop="publisher"
                        width="120"
                ></el-table-column>
                <el-table-column
                        label="浏览量"
                        align="center"
                        prop="views"
                        width="120"
                ></el-table-column>
                <el-table-column
                        label="采集时间"
                        align="center"
                        prop="createTime"
                        width="200"
                ></el-table-column>
                <el-table-column
                        prop="icon"
                        align="center"
                        width="300"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" plain @click="collectionResultsInfo(scope.row.reptileId,true)" >查看</el-button>
                        <el-button type="primary" plain @click="collectionResultsInfo(scope.row.reptileId,false)">编辑</el-button>
                        <template >
                            <el-popconfirm title="确定删除该采集结果吗？" @confirm="delHandle(scope.row.reptileId)">
                                <el-button type="danger" plain slot="reference">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="total, sizes, prev, pager, next, jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page="current"
                    :page-size="size"
                    :total="total">
            </el-pagination>
        </el-dialog>
        <CollectionResultsInfo ref="collectionResults" v-if="collectionResultsVisible" @refreshDataList="getCollectionResults" ></CollectionResultsInfo>
    </div>
</template>

<script>
    import CollectionResultsInfo from "../collectionresults/CollectionResultsInfo";
    export default {
        name: "CollectionResults",
        components:{
            CollectionResultsInfo
        },
        data(){
            return{
                dialogTableVisible:false,
                tableData:[],
                multipleSelection:[],
                collectionResultsVisible:false,
                total: 0,
                size: 10,
                current: 1,
                acquisitionTaskId:0,
                searchForm:{},
            }
        },
        methods:{
            handleClose() {
                this.dialogTableVisible=false
                this.tableData=[]
                this.multipleSelection=[]
                this.total=0
                this.size= 10
                this.current= 1
                this.acquisitionTaskId=0
                this.searchForm={}
                this.$emit('refreshDataList')
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },

            handleSizeChange(val) {
                this.size = val
                this.getCollectionResults()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getCollectionResults()
            },
            //删除资讯
            delHandle(id) {
                this.$axios.post("/admin/reptile/deleteReptileInfo ", {reptileId:id}).then(res => {
                    this.$message({
                        showClose: true,
                        message: '恭喜你，操作成功',
                        type: 'success',
                        onClose:() => {
                            this.getCollectionResults()
                        }
                    });
                })
            },
            getCollectionResults(){
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.searchForm.acquisitionTaskId=this.acquisitionTaskId
                this.$axios.post("/admin/acquisitiontask/getCollectionResults", this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.current
                    this.total = res.data.total
                })
            },
            init(id){
                this.dialogTableVisible=true
                this.acquisitionTaskId=id
                this.getCollectionResults()
            },
            tableRowClassName({row, rowIndex}) {
                if(row.isAcquisitionSucceeded==1){
                    return 'success-row';
                }else{
                    return 'warning-row';
                }
            },
            collectionResultsInfo(id,isSee){
                this.collectionResultsVisible=true
                this.$nextTick(()=>{
                    this.$refs.collectionResults.init(id,isSee)
                })
            },
        }
    }
</script>


<style>
    .dialogClass .el-dialog__body {
        padding-bottom: 60px;
    }
    .el-table .warning-row {
        background: #e74c3c;
    }
    .el-table .success-row {
        background: #f7faff;
    }
</style>
